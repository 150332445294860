import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/20/solid'
import { AddtocardList } from './../Redux/action'
import { useDispatch } from 'react-redux';
import {
    useParams, useNavigate
} from "react-router-dom";
import Sizeguard from './../assist/sizeguard.png'
import { Helmet } from 'react-helmet-async';
import PrintedMugDetail from "./../assist/PrintedMugDetail.jpg"
const product = {
    name: 'Basic Tee 6-Pack ',
    price: '$192',
    rating: 5,
    reviewCount: 267,
    href: 'https://g.co/kgs/qkQTno8',
    // imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg',
    imageAlt: 'Two each of gray, white, and black shirts arranged on table.',
    colors: [
        { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
        { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
        { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
    ],
    sizes: [
        { name: 'XXS', inStock: true },
        { name: 'XS', inStock: true },
        { name: 'S', inStock: true },
        { name: 'M', inStock: true },
        { name: 'L', inStock: true },
        { name: 'XL', inStock: true },
        { name: 'XXL', inStock: true },
        { name: 'XXXL', inStock: false },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProductDialog(props) {
    const { main } = useParams()
    const [navItem, setNavItem] = useState(main)
    const [open, setOpen] = useState(props.open)
    const [count, setarr] = useState(new Array(5).fill(null).map((_, i) => i))
    const [selectedColor, setSelectedColor] = useState(props.cardProduct.color)
    const [selectedSize, setSelectedSize] = useState(props.cardProduct.size[0])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const checkout = (items) => {
        dispatch(AddtocardList(items))
        navigate(`/login`)
    }

    const close = () => {
        setOpen(false)
    }
    //onback popup close
    // useEffect(() => {
    //     window.addEventListener("beforeunload", props.setDialog(!open));

    //     return () => {
    //         window.removeEventListener("beforeunload", props.setDialog(!open));
    //     };

    // }, [])

    // console.log(props.cardProduct, selectedSize)
    return (
        <Transition.Root show={open}
            as={Fragment}
        >

            <Dialog className="relative z-30 lg:z-30" onClose={props && props.onOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">

                    <Helmet>
                        <title>{props.cardProduct.product_name}</title>
                        <meta name="description"
                            content={props.cardProduct.product_detail}>
                        </meta>
                        <meta name="robots" content="index, follow" />
                        <meta name="robots" content="max-image-preview:large" />
                        <meta name="google-site-verification" content="vwUAwcHi4pY-Lgm6a9JJyOwn1GLbkA7gO6fvQOy4FvU" />
                        <meta name="keywords" content="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags"></meta>
                        {/* <link rel="canonical" href={`https://www.triokzz.com/product/${navItem}`} /> */}
                        <meta property="og:type" content="product" />
                        <meta property="og:title" content={props.cardProduct.product_name} />
                        <meta property="og:description" content={props.cardProduct.product_detail} />
                        <meta property="og:image" content={props.cardProduct.url} />
                        <meta property="og:image:height" content="1200" />
                        <meta property="og:image:width" content="630" />
                        {/* <meta property="og:url" content="https://www.triokzz.com/product/all" /> */}
                        <meta property="og:image:alt" content="Images T-shirt Mugs Travel Bags Mouse Pad" />
                        <meta property="og:site_name" content="Triokzz your shopping partner 🛒" />

                        {/* <!-- Google tag (gtag.js) --> */}
                        <script async="" src="https://www.googletagmanager.com/gtag/js?id=G-RWZEXW6VMP"></script>
                        <script>
                            {
                                `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "G-RWZEXW6VMP");`
                            }
                        </script>

                        {/* <!-- Google tag (gtag.js) --> */}
                        <script async="" src="https://www.googletagmanager.com/gtag/js?id=AW-11415759693"></script>
                        <script>
                            {
                                `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "AW-11415759693");`
                            }
                        </script>

                        {/* <!-- Event snippet for Add to basket (1) conversion page --> */}
                        <script>
                            {
                                `gtag("event", "conversion", {
                                send_to: "AW-11415759693/5sa9CJmbsZEZEM3WusMq",
                                });
                                `
                            }
                        </script>
                        <script>
                            {
                                `gtag("event", "conversion", {
                                send_to: "AW-11415759693/haFsCPXm5_cYEM3WusMq",
                                });
                                `
                            }
                        </script>
                        {/* <!-- Event snippet for Begin checkout conversion page --> */}
                        {/* <script>
                            {
                                `gtag("event", "conversion", {
                                send_to: "AW-11415759693/QtgvCJ6asZEZEM3WusMq",
                                });`
                            }
                        </script> */}
                        {/* <!-- Event snippet for Purchase conversion page --> */}
                        {/* <script>
                            {`gtag("event", "conversion", {
                                send_to: "AW-11415759693/XSgmCJiasZEZEM3WusMq",
                            transaction_id: "",
                                });
                            `}
                        </script> */}
                    </Helmet >
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                    <nav class="fixed top-0 left-0 w-full flex items-center justify-between flex-wrap bg-teal-500 p-6" onClick={() => props.setDialog(!open)}>
                                        <div class="flex items-center flex-shrink-0 text-white mr-6" >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                            </svg>
                                            <span class="font-semibold text-xl tracking-tight">Triokzz</span>

                                        </div>
                                    </nav>
                                    {/* <button
                                        // href="#"
                                        type="button"
                                        className="absolute right-4 top-9  rounded bg-red-700 text-white-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                                        // onClick={() => setOpen(!open)}
                                        onClick={() => props.setDialog(!open)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button> */}

                                    <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                                        {/* <div className="flex item-center justify-center overflow-y-scroll pb-2 hide-scroll-baraspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                            <ul className="flex flex-nowrap">
                                                <li className="bg-lime-100  p-1 flex items-center ">
                                                    <img src={props.cardProduct.url} alt={props.cardProduct.product_name} className="object-cover object-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer" title={props.cardProduct.product_name} />
                                                </li>
                                                <li className="bg-lime-100  p-1 flex items-center ">
                                                    <img src={props.cardProduct.url} alt={props.cardProduct.product_name} className="object-cover object-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer" title={props.cardProduct.product_name} />
                                                </li>
                                            </ul>
                                        </div> */}
                                        <div className="aspect-h-3 aspect-w-2 mt-10 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                            <img src={props.cardProduct.url} alt={props.cardProduct.product_name} className="object-cover object-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer" title={props.cardProduct.product_name} />
                                        </div>
                                        <div class="p-2  grid grid-cols-2  sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-3 xl:gap-x-8 items-center justify-center">
                                            <div className="aspect-h-1 aspect-w-1 h-50 w-48 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                                <img src={props.cardProduct.detail_url} alt={props.cardProduct.brand} className="object-cover object-center hover:scale-125  transition duration-500 ease-in-out cursor-pointer" />
                                            </div>
                                            <div className="aspect-h-1 aspect-w-1 h-50 w-48 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                                <img src={props.cardProduct.specified_image} alt={props.cardProduct.brand} loading="lazy" className="object-cover object-center hover:scale-125  transition duration-500 ease-in-out cursor-pointer" />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-8 lg:col-span-7">

                                            <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{props.cardProduct.product_name}</h2>

                                            <section aria-labelledby="information-heading" className="mt-2">
                                                <h3 id="information-heading" className="sr-only">
                                                    Product information
                                                </h3>
                                                <div className="flex m-2"> <p className="text-2xl text-gray-900">INR {props.cardProduct.offer_prize} /-</p>

                                                    <p className="text-sm  text-gray-700 line-through p-2">M.R.P. {props.cardProduct.real_prize} /-</p></div>

                                                <span class=" m-2 bg-[#E74040] px-2 text-center text-sm font-medium text-white">{props.cardProduct.offer ? props.cardProduct.offer : ""}</span>
                                                <h4 className="text-sm font-medium text-gray-900">Select Size</h4>
                                                <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">
                                                    <RadioGroup.Label className="sr-only">Choose a size</RadioGroup.Label>
                                                    <div className="mr-2 grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2">
                                                        {props.cardProduct.size.map((size, i) => (
                                                            <RadioGroup.Option
                                                                key={size}
                                                                value={size}
                                                                disabled={false}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        size && size.inStock
                                                                            ? 'cursor-pointer bg-white text-gray-900 shadow-sm'
                                                                            : 'w-32 cursor-pointer bg-gray-50 text-gray-500',
                                                                        active ? 'ring-2 ring-indigo-500' : '',
                                                                        'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                                                    )
                                                                }
                                                            >
                                                                {({ active, checked }) => (
                                                                    <>
                                                                        <RadioGroup.Label as="span" style={{ lineBreak: "anywhere" }}>{size}</RadioGroup.Label>
                                                                        {size ? (
                                                                            <span
                                                                                className={classNames(
                                                                                    active ? 'border' : 'border-2',
                                                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                                                    'pointer-events-auto absolute -inset-px rounded-md'
                                                                                )}
                                                                                aria-hidden="true"
                                                                            />
                                                                        ) : (
                                                                            <span
                                                                                aria-hidden="true"
                                                                                className="pointer-events-auto absolute -inset-px rounded-md border-2 border-gray-200"
                                                                            >
                                                                                <svg
                                                                                    className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                                                                    viewBox="0 0 100 100"
                                                                                    preserveAspectRatio="none"
                                                                                    stroke="currentColor"
                                                                                >
                                                                                    <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                                                                </svg>
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </RadioGroup.Option>
                                                        ))}
                                                    </div>
                                                </RadioGroup>
                                                <hr></hr>
                                                <button
                                                    className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent shadow-2xl  bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    onClick={() => props.onAddToCard({
                                                        name: props.cardProduct.brand,
                                                        product_name: props.cardProduct.product_name,
                                                        price: props.cardProduct.offer_prize,
                                                        real_prize: props.cardProduct.real_prize,
                                                        id: props.cardProduct.id,
                                                        count: props.cardProduct.count,
                                                        rating: 3.9,
                                                        reviewCount: 117,
                                                        freez: props.cardProduct.freez,
                                                        imageSrc: props.cardProduct.url,
                                                        imageAlt: props.cardProduct.brand,
                                                        colors: { name: selectedColor, class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
                                                        sizes: { name: selectedSize, inStock: true },
                                                        size: props.cardProduct.size,
                                                        uni_productId: props.cardProduct.uni_productId,
                                                        type: props.cardProduct.type,
                                                        detail: props.cardProduct.product_name + props.cardProduct.product_detail,
                                                        detail_url: props.cardProduct.detail_url,
                                                        delivery_charge: props.cardProduct.delivery_charge
                                                    })}
                                                >
                                                    Add To Cart
                                                </button>
                                                <button
                                                    className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent shadow-2xl shadow-transparent bg-gray-900 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    onClick={() => checkout([{
                                                        name: props.cardProduct.brand,
                                                        price: props.cardProduct.offer_prize,
                                                        id: props.cardProduct.id,
                                                        count: props.cardProduct.count,
                                                        rating: 4.5,
                                                        reviewCount: 117,
                                                        imageSrc: props.cardProduct.url,
                                                        imageAlt: props.cardProduct.brand,
                                                        colors: { name: selectedColor, class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
                                                        sizes: { name: selectedSize, inStock: true },
                                                        type: props.cardProduct.type,
                                                        detail: props.cardProduct.product_name,
                                                        delivery_charge: props.cardProduct.delivery_charge
                                                    }])}
                                                >
                                                    Buy Now
                                                </button>
                                                <ul class="mb-8 mt-8 space-y-6 text-left text-lg text-gray-500">
                                                    <li class="flex items-center space-x-4">

                                                        <svg class="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span>Trusted Brand By Triokzz</span>
                                                    </li>
                                                    <li class="flex items-center space-x-4">

                                                        <svg class="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span>Easy Return on damaged/wrong shipping</span>
                                                    </li>
                                                    <li class="flex items-center space-x-4">

                                                        <svg class="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span>24/7 Customer support </span>
                                                    </li>
                                                    <li class="flex items-center space-x-4">

                                                        <svg class="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span>020-47153-653</span>
                                                    </li>
                                                </ul>
                                                {/* Reviews */}
                                                <div className="mt-6 mb-6">
                                                    <h4 className="sr-only">Reviews</h4>
                                                    <div className="flex items-center">
                                                        <div className="flex items-center">
                                                            {count.map((rating) => (
                                                                <StarIcon
                                                                    key={rating}
                                                                    className={classNames(
                                                                        props.cardProduct.rating > rating ? 'text-green-900' : 'text-gray-200',
                                                                        'h-5 w-5 flex-shrink-0'
                                                                    )}
                                                                    aria-hidden="true"
                                                                    onClick={() => setarr(new Array(rating + 1).fill(null).map((_, i) => i))}
                                                                />
                                                            ))}
                                                        </div>
                                                        <p className="sr-only">{props.cardProduct.rating} out of 5 stars</p>
                                                        <a href="https://g.page/r/CWgeQHoTuMV2EBI/review" className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                                            {product.reviewCount} reviews
                                                        </a>
                                                    </div>
                                                    {/* rating bar  */}
                                                    <div class="flex items-center mt-4">
                                                        <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">5 star</a>
                                                        <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                            <div class="h-5 bg-yellow-300 rounded" style={{ width: "70%" }}></div>
                                                        </div>
                                                        <span class="text-sm font-medium text-gray-500 dark:text-gray-400">70%</span>
                                                    </div>
                                                    <div class="flex items-center mt-4">
                                                        <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">4 star</a>
                                                        <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                            <div class="h-5 bg-yellow-300 rounded" style={{ width: "17%" }}></div>
                                                        </div>
                                                        <span class="text-sm font-medium text-gray-500 dark:text-gray-400">17%</span>
                                                    </div>
                                                    <div class="flex items-center mt-4">
                                                        <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">3 star</a>
                                                        <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                            <div class="h-5 bg-yellow-300 rounded" style={{ width: "8%" }}></div>
                                                        </div>
                                                        <span class="text-sm font-medium text-gray-500 dark:text-gray-400">8%</span>
                                                    </div>
                                                    <div class="flex items-center mt-4">
                                                        <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">2 star</a>
                                                        <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                            <div class="h-5 bg-yellow-300 rounded" style={{ width: "4%" }}></div>
                                                        </div>
                                                        <span class="text-sm font-medium text-gray-500 dark:text-gray-400">4%</span>
                                                    </div>
                                                    <div class="flex items-center mt-4">
                                                        <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">1 star</a>
                                                        <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                            <div class="h-5 bg-yellow-300 rounded" style={{ width: "4%" }}></div>
                                                        </div>
                                                        <span class="text-sm font-medium text-gray-500 dark:text-gray-400">1%</span>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="flex">
                                                    <div class="flex items-center h-5">
                                                        <input id="helper-radio" aria-describedby="helper-radio-text" type="radio" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                    </div>
                                                    <div class="ms-2 text-sm">
                                                        <label for="helper-radio" class="font-medium text-gray-900 dark:text-gray-300">Free delivery on rs.1449</label>
                                                        <p id="helper-radio-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">For order within a day</p>
                                                    </div>
                                                </div>
                                            </section>
                                            <hr></hr>

                                            <section aria-labelledby="options-heading" className="mt-10">
                                                <h3 id="options-heading" className="sr-only">
                                                    Product options
                                                </h3>

                                                {/* <form> */}
                                                {/* Colors */}
                                                <div className="flex">
                                                    <h4 className="capitalize text-sm font-medium text-gray-900">Color: {props.cardProduct.color}</h4>

                                                    <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-4">
                                                        <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                                                        <span className="flex items-center space-x-3">
                                                            {/* {props.cardProduct.color} */}
                                                            {/* {props.cardProduct.color.map((color) => (
                                                                <RadioGroup.Option
                                                                    key={color.name}
                                                                    value={color}
                                                                    className={({ active, checked }) =>
                                                                        classNames(
                                                                            color.selectedClass,
                                                                            active && checked ? 'ring ring-offset-1' : '',
                                                                            !active && checked ? 'ring-2' : '',
                                                                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                                                                        )
                                                                    }
                                                                >
                                                                    <RadioGroup.Label as="span" className="sr-only">
                                                                        {color.name}
                                                                    </RadioGroup.Label>
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className={classNames(
                                                                            color.class,
                                                                            'h-8 w-8 rounded-full border border-black border-opacity-10'
                                                                        )}
                                                                    />
                                                                </RadioGroup.Option>
                                                            ))} */}
                                                        </span>
                                                    </RadioGroup>

                                                </div>
                                                <div>
                                                    {props.cardProduct.type.includes("Tshirt") ?
                                                        <img src={Sizeguard} alt="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags" className="object-cover object-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer" /> :
                                                        props.cardProduct.type.includes("Ceramic" || "Mug") ?
                                                            <img src={PrintedMugDetail} alt="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags" className="object-cover object-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer" /> :
                                                            <img src={props.cardProduct.sizeguide_url} loading="lazy" alt="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags" className="object-cover object-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer" />}
                                                </div>
                                                {/* Sizes */}
                                                <div className="mt-10">
                                                    <div className="flex items-center justify-between">
                                                        <h4 className="text-sm font-medium text-gray-900">Size</h4>
                                                        {props.cardProduct.brand.toLowerCase().includes("jigari") ? <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                                            Size guide
                                                        </a> : ""}
                                                        {/* <Sizeguard /> */}
                                                    </div>


                                                </div>

                                                <div class="flex">
                                                    <p className="flex items-center mb-3 text-gray-800 dark:text-gray-400 first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-gray-900 dark:first-letter:text-gray-300 first-letter:me-3 first-letter:float-start">
                                                        {props.cardProduct.product_detail}

                                                    </p>
                                                </div>
                                                <hr></hr>
                                                <div className="p-1 flex items-center justify-between">
                                                    <h4 className="text-sm font-medium text-gray-900"> Ships in 2-7 days </h4>
                                                    <a href="/privacy" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                                        Agree Policy
                                                    </a>
                                                    {/* <Sizeguard /> */}
                                                </div>
                                                <div class="m-1 flex items-center justify-between bg-teal-500 p-2">
                                                    <button
                                                        id="addtocart"
                                                        className="m-2 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={() => props.onAddToCard({
                                                            name: props.cardProduct.brand,
                                                            product_name: props.cardProduct.product_name,
                                                            price: props.cardProduct.offer_prize,
                                                            real_prize: props.cardProduct.real_prize,
                                                            id: props.cardProduct.id,
                                                            count: props.cardProduct.count,
                                                            rating: 3.9,
                                                            reviewCount: 117,
                                                            freez: props.cardProduct.freez,
                                                            imageSrc: props.cardProduct.url,
                                                            imageAlt: props.cardProduct.brand,
                                                            colors: { name: selectedColor, class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
                                                            sizes: { name: selectedSize, inStock: true },
                                                            size: props.cardProduct.size,
                                                            uni_productId: props.cardProduct.uni_productId,
                                                            type: props.cardProduct.type,
                                                            detail: props.cardProduct.product_name + props.cardProduct.product_detail,
                                                            detail_url: props.cardProduct.detail_url,
                                                            delivery_charge: props.cardProduct.delivery_charge
                                                        })}
                                                    >
                                                        Add To Cart
                                                    </button>
                                                    <button
                                                        id="Buynow"
                                                        className="m-2 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-900 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={() => checkout([{
                                                            name: props.cardProduct.brand,
                                                            price: props.cardProduct.offer_prize,
                                                            id: props.cardProduct.id,
                                                            count: props.cardProduct.count,
                                                            rating: 4.5,
                                                            reviewCount: 117,
                                                            imageSrc: props.cardProduct.url,
                                                            imageAlt: props.cardProduct.brand,
                                                            colors: { name: selectedColor, class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
                                                            sizes: { name: selectedSize, inStock: true },
                                                            type: props.cardProduct.type,
                                                            detail: props.cardProduct.product_name,
                                                            delivery_charge: props.cardProduct.delivery_charge
                                                        }])}
                                                    >
                                                        Buy Now
                                                    </button>
                                                </div>
                                                {/* </form> */}
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>

                </div>
                <div typeof="schema:Product">
                    <div rel="schema:review">
                        <div typeof="schema:Review">
                            <div rel="schema:reviewRating">
                                <div typeof="schema:Rating">
                                    <div property="schema:ratingValue" content="4"></div>
                                    <div property="schema:bestRating" content="5"></div>
                                </div>
                            </div>
                            <div rel="schema:author">
                                <div typeof="schema:Person">
                                    <div property="schema:name" content="Fred Benson"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div rel="schema:image" resource={props.cardProduct.url}></div>
                    <div property="schema:mpn" content={props.cardProduct.id}></div>
                    <div property="schema:name" content={props.cardProduct.type}></div>
                    <div property="schema:description" content={props.cardProduct.product_detail}></div>
                    {/* <div rel="schema:image" resource={props.cardProduct.url}></div> */}
                    <div rel="schema:brand">
                        <div typeof="schema:Brand">
                            <div property="schema:name" content={props.cardProduct.brand}></div>
                        </div>
                    </div>
                    <div rel="schema:aggregateRating">
                        <div typeof="schema:AggregateRating">
                            <div property="schema:reviewCount" content="389"></div>
                            <div property="schema:ratingValue" content="4.4"></div>
                        </div>
                    </div>
                    <div rel="schema:offers">
                        <div typeof="schema:Offer">
                            <div property="schema:price" content={`${props.cardProduct.offer_prize}.00`}></div>
                            <div property="schema:availability" content="https://schema.org/InStock"></div>
                            <div property="schema:priceCurrency" content="INR"></div>
                            <div property="schema:priceValidUntil" datatype="xsd:date" content="2025-11-20"></div>
                            <div rel="schema:url" resource={`https://triokzz.com/product/${navItem}/${props.cardProduct.type} ${props.cardProduct.color}`}></div>
                            <div property="schema:itemCondition" content="https://schema.org/NewCondition"></div>
                            <div rel="schema:shippingDetails">
                                <div typeof="schema:OfferShippingDetails">
                                    <div rel="schema:shippingRate">
                                        <div typeof="schema:MonetaryAmount">
                                            <div property="schema:value" content="0.00"></div>
                                            <div property="schema:currency" content="INR"></div>
                                        </div>
                                    </div>
                                    <div rel="schema:shippingDestination">
                                        <div typeof="schema:DefinedRegion">
                                            <div property="schema:addressCountry" content="IN"></div>
                                        </div>
                                    </div>
                                    <div rel="schema:deliveryTime">
                                        <div typeof="schema:ShippingDeliveryTime">
                                            <div rel="schema:handlingTime">
                                                <div typeof="schema:QuantitativeValue">
                                                    <div property="schema:minValue" content="0"></div>
                                                    <div property="schema:maxValue" content="1"></div>
                                                    <div property="schema:unitCode" content="DAY"></div>
                                                </div>
                                            </div>
                                            <div rel="schema:transitTime">
                                                <div typeof="schema:QuantitativeValue">
                                                    <div property="schema:minValue" content="1"></div>
                                                    <div property="schema:maxValue" content="5"></div>
                                                    <div property="schema:unitCode" content="DAY"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div rel="schema:hasMerchantReturnPolicy">
                                <div typeof="schema:MerchantReturnPolicy">
                                    <div property="schema:applicableCountry" content="IN"></div>
                                    <div property="schema:returnPolicyCategory" content="https://schema.org/MerchantReturnFiniteReturnWindow"></div>
                                    <div property="schema:merchantReturnDays" content="30"></div>
                                    <div property="schema:returnMethod" content="https://schema.org/ReturnByMail"></div>
                                    <div property="schema:returnFees" content="https://schema.org/FreeReturn"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div rel="schema:image" resource="https://example.com/photos/16x9/photo.jpg"></div> */}
                    <div property="schema:sku" content={props.cardProduct.id}></div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
