import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';



const Paymentgetway = () => {
    const navigate = useNavigate()
    const userstate = useLocation()
    const [stateofcountry, setstateofcountry] = useState("")
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    let session = JSON.parse(sessionStorage.getItem('PHONEOTPINFO'))

    let ListOfOrdered = useSelector(state => state.allProducts.order)

    // let subtotal = ListOfOrdered && ListOfOrdered.reduce((acc, curr) => {
    //     return acc + curr.price
    // }, 0)
    let subtotal = ListOfOrdered && ListOfOrdered.reduce((acc, curr) => {
        let itemtotal = curr.price * (curr.count ? curr.count : 1)
        return acc += itemtotal
    }, 0)


    let pincode = watch('delivery_zip')
    useEffect(() => {

        // console.log('billing-zip', pincode)
        if (pincode) {
            setTimeout(() => {
                axios.get(`https://api.postalpincode.in/pincode/${parseInt(pincode)}`)
                    .then(r => setstateofcountry(r.data))
            }, 2000)

        }


    }, [pincode])


    const onSubmit = (data, e) => {
        // console.log(data, e)
        // const withpaymentsuccessful = {
        //     "product_id": 1,
        //     "product_for": userstate.state.name,
        //     "email": userstate.state.email,
        //     "picture": userstate.state.picture,
        //     "location": userstate.state.locale,
        //     "section": ListOfOrdered,
        //     "date": new Date(),
        //     "approvedby_admin": "Not Approved",
        //     "delivered": false,
        //     "received": false,
        //     "total": subtotal
        // }
        // onBuyClicked()
        // console.log("withpaymentsuccessful", withpaymentsuccessful)
        // navigate("/about", {
        //     data: data
        // })
        // console.log("data", data)

        var form = document.createElement("form");
        form.target = "_self";
        form.method = "POST";
        form.action = "https://api.triokzz.com/ccavRequestHandler";
        var params = {
            "merchant_id": data && data.merchant_id,
            "amount": data && data.amount,
            "billing_address": data && data.billing_address,
            "billing_email": data && data.billing_email,
            "billing_name": data && data.billing_name,
            "delivery_name": data && data.delivery_name,
            "currency": data && data.currency,
            "delivery_country": "India",
            "delivery_address": data && data.delivery_address,
            "delivery_city": data && data.delivery_city,
            "billing_city": data && data.delivery_city,
            "delivery_state": data && data.delivery_state,
            "billing_state": data && data.delivery_state,
            "billing_country": "India",
            "billing_tel": data && data.delivery_tel,
            "delivery_tel": data && data.delivery_tel,
            "delivery_zip": data && data.delivery_zip,
            "billing_zip": data && data.delivery_zip,
            "language": data && data.language,
            "order_id": data && data.order_id,
            "redirect_url": data && data.redirect_url,
            "cancel_url": data && data.cancel_url
        };

        // var params = encodeURI(data)

        for (var i in params) {
            if (params.hasOwnProperty(i)) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        form.submit();

        setTimeout(() => {
            window.open('', '_self');
        }, 100);
        // window.open('', 'view');
        // var url = 'https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/ccavRequestHandler'
        // var datas = JSON.stringify(data)

        // const controller = new AbortController()

        // 5 m timeout:

        // const timeoutId = setTimeout(() => controller.abort(), 10000)
        // setTimeout(() => {
        axios.post("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products", {
            "product_id": 1,
            "order_id": data && data.order_id,
            "section": ListOfOrdered,
            "email": data && data.billing_email,
            "phone": data && data.delivery_tel,
            "delivery_name": data && data.delivery_name,
            "billing_address": data && data.delivery_address,
            "street_address": data && data.billing_address,
            "state": data && data.delivery_state,
            "city": data && data.delivery_city,
            "pin_code": data && data.delivery_zip,
            // "payment_success": result.order_status,
            "payment_success": "Inprogress",
            "payment_location": "en",
            "approvedby_admin": "Approved",
            "delivered": false,
            "received": false,
            // "date": result.trans_date,
            // "date": "19/12/2023 01:49:04",
            "total": data && data.amount,
            "product_for": data && data.billing_name,
            // "tracking_id": result.tracking_id
            // "tracking_id": "152865758552"
        }).then(r => r).catch(e => e)
        localStorage.clear()
        // }, ((5 * 60) * 1000))
    }

    return (<div>
        <Helmet>
            {/* <!-- Google tag (gtag.js) --> */}
            <script async="" src="https://www.googletagmanager.com/gtag/js?id=G-RWZEXW6VMP"></script>
            <script>
                {
                    `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "G-RWZEXW6VMP");`
                }
            </script>

            {/* <!-- Google tag (gtag.js) --> */}
            <script async="" src="https://www.googletagmanager.com/gtag/js?id=AW-11415759693"></script>
            <script>
                {
                    `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "AW-11415759693");`
                }
            </script>

            {/* <!-- Event snippet for Add to basket (1) conversion page --> */}
            {/* <script>
                {
                    `gtag("event", "conversion", {
                                send_to: "AW-11415759693/5sa9CJmbsZEZEM3WusMq",
                                });
                                `
                }
            </script>
            <script>
                {
                    `gtag("event", "conversion", {
                                send_to: "AW-11415759693/haFsCPXm5_cYEM3WusMq",
                                });
                                `
                }
            </script> */}
            {/* <!-- Event snippet for Begin checkout conversion page --> */}
            {/* <script>
                            {
                                `gtag("event", "conversion", {
                                send_to: "AW-11415759693/QtgvCJ6asZEZEM3WusMq",
                                });`
                            }
                        </script> */}
            {/* <!-- Event snippet for Purchase conversion page --> */}
            <script>
                {`gtag("event", "conversion", {
                                send_to: "AW-11415759693/XSgmCJiasZEZEM3WusMq",
                            transaction_id: "",
                                });
                            `}
            </script>
            <script>
                {
                    `!(function (f, b, e, v, n, t, s) {
                           if (f.fbq) return;
                            n = f.fbq = function () {
                                n.callMethod
                                    ? n.callMethod.apply(n, arguments)
                                    : n.queue.push(arguments);
                           };
                            if (!f._fbq) f._fbq = n;
                            n.push = n;
                            n.loaded = !0;
                            n.version = "2.0";
                            n.queue = [];
                            t = b.createElement(e);
                            t.async = !0;
                            t.src = v;
                            s = b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t, s);
                            })(
                            window,
                            document,
                            "script",
                            "https://connect.facebook.net/en_US/fbevents.js"
                            );
                            fbq("init", "232698863167434");
                            fbq("track", "PageView");
                            fbq("track", "AddToCart");
                        `}
            </script>
            <noscript
            >
                {`
                                <img
                                height="1"
                                width="1"
                                style="display: none"
                                src="https://www.facebook.com/tr?id=232698863167434&ev=PageView&noscript=1"
                            />
                         `}
            </noscript>
        </Helmet >
        <form
            onSubmit={handleSubmit(onSubmit)}
        // method="POST" name="customerData" action="https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/ccavRequestHandler"
        >
            {/* <div class="h-screen w-full flex flex-col items-center justify-center bg-teal-lightest font-sans">
                <div v-if="modal.visible" class="h-screen w-full absolute flex items-center justify-center bg-modal">
                    <div class="bg-white rounded shadow p-8 m-4 max-w-xs max-h-full text-center overflow-y-scroll">
                        <div class="mb-4">
                            <h1>Welcome!</h1>
                        </div>
                        <div class="mb-8">
                            <p>Triokzz still in testing mode.We sorry for inconvenience but yes, triokzz will be best option for you soon..</p>
                        </div>
                        <div class="flex justify-center">
                            <button class="flex-no-shrink text-white py-2 px-4 rounded bg-teal hover:bg-teal-dark">Thank you</button>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="mt-8 bg-gray-50 px-4 pt-8 lg:mt-0">
                <p className="text-xl text-white  bg-green-500 font-medium">Payment Details</p>
                <p className="text-white bg-black">Complete your order by providing your correct payment details.</p>

                <div className="">
                    <label for="billing_name" className="mt-4 mb-2 block text-sm font-medium">Card Holder</label>
                    <div className="relative">
                        <input type="text" id="billing_name" {...register("billing_name", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:ring-sky-400" placeholder="Full name of card holder" />
                        {errors.billing_name && <span style={{ color: "red" }}>please enter your full name</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                            </svg>
                        </div>
                    </div>
                    <label for="billing_email" className="mt-4 mb-2 block text-sm font-medium">Email</label>
                    <div className="relative">
                        <input type="billing_email" id="billing_email" {...register("billing_email", { required: true })} value={"" || userstate && userstate.state && userstate.state.email} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:ring-sky-400" placeholder="Email@gmail.com" />
                        {errors.billing_email && <span style={{ color: "red" }}>please enter email id</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                        </div>
                    </div>
                    <div className="relative">
                        <input type="text" id="delivery_name" {...register("delivery_name", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:ring-sky-400" placeholder="Receiver name" />
                        {errors.delivery_name && <span style={{ color: "red" }}>please enter receiver name</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                            </svg>
                        </div>

                    </div>

                    {/* <label for="card-no" className="mt-4 mb-2 block text-sm font-medium">Card Details</label>
                    <div className="flex">
                        <div className="relative w-7/12 flex-shrink-0">
                            <input type="text" id="card-no" {...register("card-no")} className="w-full rounded-md border border-gray-200 px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:ring-sky-400" placeholder="xxxx-xxxx-xxxx-xxxx" />
                            <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                                </svg>
                            </div>
                        </div>
                        <input type="text" {...register("credit-expiry")} className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:ring-sky-400" placeholder="MM/YY" />
                        <input type="number" {...register("credit-cvc")} className="w-1/6 flex-shrink-0 rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:ring-sky-400" placeholder="CVC" />
                    </div> */}
                    <label for="delivery_address" className="mt-4 mb-2 block text-sm text-white font-medium bg-green-500">Billing Address</label>
                    <div className="flex grid grid-cols-1 lg:grid-cols-2  sm:flex-row">
                        <input type="text" {...register("delivery_zip", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="Pincode" />


                    </div>
                    <div className="relative">
                        <select type="text" {...register("delivery_state", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 
                         invalid:border-pink-500">
                            {stateofcountry && stateofcountry[0].PostOffice && stateofcountry[0].PostOffice.map((stateof, i) => <option value={stateof.Circle}>{stateof.Circle}</option>)}
                        </select>
                        <select type="text" {...register("delivery_city", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 
                         invalid:border-pink-500">
                            {stateofcountry && stateofcountry[0].PostOffice && stateofcountry[0].PostOffice.map((stateof, i) => <option value={stateof.Name}>{stateof.Name}</option>)}
                        </select>
                    </div>
                    {/* <div className="relative">
                        <input type="text" {...register("delivery_state", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="delivery state" />
                        <input type="text" {...register("delivery_city", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="delivery city" />

                    </div> */}
                    <div className="relative">
                        <input type="text" id="delivery_address" {...register("delivery_address", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 invalid:border-pink-500" placeholder="Flat no./house/socity/delivery Address" />
                        {errors.delivery_address && <span style={{ color: "red" }}>delivery address is required</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <img className="h-4 w-4 object-contain" src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png" alt="" />
                        </div>
                    </div>
                    <div className="relative">
                        <input type="text" id="billing-address" {...register("billing_address", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 invalid:border-pink-500" placeholder="Street address/near by location" />
                        {errors.billing_address && <span style={{ color: "red" }}>billing address is required</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <img className="h-4 w-4 object-contain" src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png" alt="" />
                        </div>
                    </div>
                    <div className=" grid grid-cols-1 lg:grid-cols-1  sm:flex-row">
                        <input type="text" name="delivery_tel" value={"" || session && session.phoneNumber && session.phoneNumber.slice(3, 13)} {...register("delivery_tel", { required: true, maxLength: 10, pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/ })}
                            className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10  invalid:border-pink-500" placeholder="Mobile number" />
                        {errors.delivery_tel && <span style={{ color: "red" }}>please enter your 10 digit mobile number</span>}
                    </div>
                    <label for="delivery_address" className="mt-4 mb-2 block text-sm text-white bg-green-500 font-medium">Suggestion</label>
                    <div className="flex grid grid-cols-1 lg:grid-cols-1  sm:flex-row">

                        <input type="text" {...register("merchant_param1")} placeholder="To improve our quality of service ..." className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:ring-sky-400" />
                    </div>
                    {/* total payment  */}
                    <div class="flex items-center ">
                        <input id="helper-radio" aria-describedby="helper-radio-text" type="radio" value="true" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <span className="text-gray-500">Billing address is the same as shipping address</span>
                    </div>


                    <div className="mt-6 border-t border-b py-2">
                        <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-gray-900">Subtotal</p>
                            <p className="font-semibold text-gray-900">{subtotal}.00 Rs</p>
                        </div>
                        {subtotal < 1449 && <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-gray-900">Shipping</p>
                            <p className="font-semibold text-gray-900">{ListOfOrdered && ListOfOrdered[0].delivery_charge}.00 Rs</p>
                        </div>}
                    </div>
                    <div className="mt-6 flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">Total</p>
                        <p className="text-2xl font-semibold text-green-500">₹ {subtotal < 1449 ? (subtotal + (ListOfOrdered && ListOfOrdered[0].delivery_charge)) && (subtotal + (ListOfOrdered && ListOfOrdered[0].delivery_charge)).toString() : subtotal && subtotal.toString()} /-</p>
                    </div>

                    <table width="40%" height="100" border='1' align="right">
                        <tr>
                            {/* <td>Redirect URL</td> */}
                            <td><input type="hidden" name="redirect_url" {...register("redirect_url", { required: true })} value="https://api.triokzz.com/ccavResponseHandler" />
                            </td>
                        </tr>
                        <tr>
                            {/* <td>Cancel URL</td> */}
                            <td><input type="hidden" name="cancel_url" {...register("cancel_url", { required: true })} value="https://api.triokzz.com/ccavResponseHandler" />
                            </td>
                        </tr>
                        <tr disabled>
                            {/* <td>Merchant Id</td> */}
                            <td><input type="hidden"  {...register("merchant_id", { required: true })} id="merchant_id" value="2976964" /> </td>
                        </tr>
                        <tr>
                            {/* <td>Order Id</td> */}
                            {/* <td><input type="hidden" {...register("order_id", { required: true })} value={((Math.random().toString(10).slice(2)).toString()) || (userstate && userstate.jti.split("").splice(0, 5).join("") + userstate & userstate.exp)} /></td> */}
                            <td><input type="hidden" {...register("order_id", { required: true })} value={(userstate && userstate.state && userstate.state.jti && userstate.state.jti.split("").splice(0, 5).join("") + userstate && userstate.state && userstate.state.exp) || (session && session.apiKey && session.apiKey.split("").splice(0, 5).join("") + subtotal && subtotal.toString())} /></td>

                        </tr>
                        <tr>
                            <td>Currency</td>
                            <td><input type="text" name="currency" {...register("currency", { required: true })} value="INR" /></td>
                        </tr>
                        <tr>
                            <td>Amount(₹)</td>
                            <td><input type="text" {...register("amount", { required: true })} value={subtotal < 1449 ? (subtotal + (ListOfOrdered && ListOfOrdered[0].delivery_charge)) && (subtotal + (ListOfOrdered && ListOfOrdered[0].delivery_charge)).toString() : subtotal && subtotal.toString()} readOnly /></td>
                            {/* <td><input type="text" name="amount"  {...register("amount", { required: true })} value="1.00" /></td> */}
                        </tr>
                        <tr>
                            <td>Language</td>
                            <td><input type="text" {...register("language", { required: true })} id="language" value="EN" /></td>
                        </tr>

                    </table>
                    <button className="relative flex w-full rounded-md bg-gray-900 font-medium text-white text-center">

                        <input type="submit" className="w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white" value="Go Checkout ⇨" />
                        <div class="m-auto p-2 bg-indigo-500 text-white overflow-hidden rounded-full  mx-auto shadow-lg flex justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                            </svg>
                        </div>
                    </button>

                </div>
                <div>
                    <p class="mt-2 text-center text-gray-500 dark:text-gray-400 sm:mt-2 lg:text-left">
                        Payment processed by <a href="#" title="" class="font-medium text-primary-700 underline hover:no-underline dark:text-primary-500">CCAvenue</a> for <a href="#" title="" class="font-medium text-primary-700 underline hover:no-underline dark:text-primary-500">Triokzz</a>
                        - United Nation
                    </p>
                </div>
            </div>
        </form>
        {/* <h4>or</h4>
        <div id="google_pay_iframe">
            <div id="container"></div>
        </div>


        <button onClick={() => onBuyClicked()} class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white">Buy Via Upi</button> */}
    </div>
    )
}

export default Paymentgetway